import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Switch, message } from 'antd';
// import {EditTwoTone} from '@ant-design/icons';

import styles from '../pages/ProgramaRecompensa/Promociones/BannersPromo/ListPromsBanners.module.css';
import PromotionsContext from '../../context/programaRecompensa/Promociones/PromotionsContext';
import UtilidadesContext from '../../context/utilidades/UtilidadesContext';
import getErrorMessage from '../utils-components/getErrorMessage';
import {
    formatoFechaSinHora,
    getOnlyHourFromADate,
} from '../utils-components/Date';

const useListPromsBanners = () => {
    const { obtenerFranquicias, obtenerSucursales } =
        useContext(UtilidadesContext);
    const {
        getPromotionsList,
        setNewOrEditProm,
        promotionsList,
        setId,
        setEnable,
        setShowDeactivatePromotionModal,
        setShowActivatePromotionModal,
        setCurrentPage,
        currentPage,
        filters,
        setFilters,
    } = useContext(PromotionsContext);

    const [form] = Form.useForm();
    const history = useHistory();

    const [dataSource, setDatasource] = useState([]);
    const [franchises, setFranchises] = useState([]);
    const [franchiseSelected, setFranchiseSelected] = useState(null);
    const [branches, setBranches] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(0);
    const [total, setTotal] = useState(0);

    const columns = [
        {
            title: 'Nro',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: 'Promoción',
            dataIndex: 'promotion',
            key: 'promotion',
        },
        {
            title: 'Franquicia',
            dataIndex: 'franchise',
            key: 'franchise',
        },
        {
            title: 'Sucursal',
            dataIndex: 'branch',
            key: 'branch',
        },
        {
            title: 'Rango fechas',
            dataIndex: 'dates',
            key: 'dates',
        },
        {
            title: 'Rango horas',
            dataIndex: 'hours',
            key: 'hours',
        },
        {
            title: 'Estado',
            dataIndex: 'state',
            key: 'state',
            render: (text, record) => (
                <div className={styles.state}>
                    <Switch
                        onClick={(checked) =>
                            handleClickSwitch(checked, record)
                        }
                        checked={record?.checked}
                    />
                    {/* <EditTwoTone onClick={() => goToEditView(record?.id)} /> */}
                </div>
            ),
        },
    ];

    const handleClickSwitch = (checked, record) => {
        const id = record?.id;
        setId(id);
        setEnable(checked);

        if (checked) {
            setShowActivatePromotionModal(true);
        }

        if (!checked) {
            setShowDeactivatePromotionModal(true);
        }
    };

    const formatDataSource = (data) => {
        const promotions = data?.promotions;
        const itemsPerPage = data?.per_page;
        const totalItems = data?.count || 0;
        const dataSource = [];

        promotions?.forEach((promotion) => {
            const dateFrom = formatoFechaSinHora(promotion?.from);
            const dateTo = formatoFechaSinHora(promotion?.to);

            const hourFrom = getOnlyHourFromADate(promotion?.from);
            const hourTo = getOnlyHourFromADate(promotion?.to);

            dataSource.push({
                key: promotion?.id,
                id: promotion?.id,
                number: promotion?.id,
                promotion: promotion?.name,
                franchise: promotion?.franchise,
                branch: promotion?.branch,
                dates: `${dateFrom} - ${dateTo}`,
                hours: `${hourFrom} a ${hourTo}`,
                checked: promotion?.active,
            });
        });

        setDatasource(dataSource);
        setPageSize(itemsPerPage);
        setTotal(totalItems);
    };

    const formatStateOfPromotion = (state) => {
        let stateFormatted;
        if (state === 'actives-and-inactives' || state === undefined)
            stateFormatted = undefined;
        if (state === 'actives') stateFormatted = true;
        if (state === 'inactives') stateFormatted = false;
        return stateFormatted;
    };

    const saveFilters = (promotion, state, franchise, branch) => {
        setFilters({
            promotion,
            state,
            franchise,
            branch,
        });
    };

    const handleFinish = (data, rememberOldFilters = false) => {
        dataSource.length && setDatasource([]);
        setLoading(true);

        form.validateFields()
            .then((filters) => {
                let searchParams,
                    searchQuery,
                    state,
                    promotion,
                    stateFormatted,
                    franchise,
                    branch;

                if (!rememberOldFilters) {
                    searchQuery = {};

                    for (const [key, value] of Object.entries(filters)) {
                        const filterName = key;
                        const filterValue = value;
                        filterValue && (searchQuery[filterName] = filterValue);
                    }

                    searchParams = new URLSearchParams(searchQuery);
                }

                if (rememberOldFilters) {
                    searchQuery = window.location.search;
                    searchParams = new URLSearchParams(searchQuery);
                }

                history.push(`?${searchParams}`);

                state = searchParams.get('state');

                promotion = searchParams.get('promotion');
                stateFormatted = formatStateOfPromotion(state);
                franchise = searchParams.get('franchise');
                branch = searchParams.get('branch');

                saveFilters(promotion, stateFormatted, franchise, branch);
            })
            .catch((error) => {
                const errorMessage = getErrorMessage(error);
                message.error(errorMessage);
            });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        handleFinish();
    };

    const goToEditView = (id) => {
        setNewOrEditProm('edit');
        history.push(
            `/programa-recompensa/promociones/banners-promo/editar-promo/${id}`,
        );
    };

    const goToNewPromView = () => {
        setNewOrEditProm('new');
        history.push(
            '/programa-recompensa/promociones/banners-promo/nueva-promo',
        );
    };

    useEffect(
        () => obtenerFranquicias().then((response) => setFranchises(response)),
        [],
    );

    useEffect(() => formatDataSource(promotionsList), [promotionsList]);

    useEffect(() => {
        franchiseSelected &&
            obtenerSucursales(franchiseSelected).then((response) =>
                setBranches(response),
            );
    }, [franchiseSelected]);

    useEffect(() => {
        if (filters)
            getPromotionsList(
                filters.promotion,
                filters.state,
                filters.franchise,
                filters.branch,
                currentPage,
            ).then(() => setLoading(false));
    }, [filters]);

    return {
        form,
        franchises,
        branches,
        loading,
        columns,
        dataSource,
        pageSize,
        total,
        currentPage,

        goToNewPromView,
        handleFinish,
        setFranchiseSelected,
        handlePageChange,
    };
};

export default useListPromsBanners;
