import React from 'react';
import ABMLayout from '../../../../../layouts/ABMLayout';
import styles from './ListPromsBanners.module.css';
import { Button, Form, Input, Row, Col, Card, Select, Table, Spin } from 'antd';
import DeactivatePromotionModal from '../../../../organisms/ProgramaRecompensas/Promociones/BannersPromo/DeactivatePromotionModal';
import ActivatePromotionModal from '../../../../organisms/ProgramaRecompensas/Promociones/BannersPromo/ActivatePromotionModal';
import ProtectedComponent from '../../../../../hoc/ProtectedComponent';
import { Permisos } from '../../../../../constants/Permisos';
import useListPromsBanners from '../../../../hooks/useListPromsBanners';

const ListPromsBanners = () => {
    const {
        form,
        franchises,
        branches,
        loading,
        columns,
        dataSource,
        pageSize,
        total,
        currentPage,

        goToNewPromView,
        handleFinish,
        setFranchiseSelected,
        handlePageChange,
    } = useListPromsBanners();

    const { Option } = Select;

    const NewPromButton = () => (
        <ProtectedComponent
            permiso={Permisos.programaRecompensa.promociones.crearEditarBanner}>
            <Button type="primary" onClick={goToNewPromView}>
                Nueva promo
            </Button>
        </ProtectedComponent>
    );

    return (
        <ABMLayout
            titulo="Banners promocionales"
            loading={false}
            acciones={<NewPromButton />}>
            <Card>
                <Row>
                    <Form layout="vertical" form={form} onFinish={handleFinish}>
                        <Row gutter={15} align="middle">
                            <Col span={1 / 7}>
                                <Form.Item
                                    label="Nombre de la promoción"
                                    name="promotion"
                                    className={styles.itemSize}>
                                    <Input className={styles.inputSize} />
                                </Form.Item>
                            </Col>

                            <Col span={1 / 7}>
                                <Form.Item
                                    label="Estado de la promoción"
                                    name="state"
                                    className={styles.itemSize}>
                                    <Select className={styles.inputSize}>
                                        <Option value="actives-and-inactives">
                                            Activos e inactivos
                                        </Option>
                                        <Option value="actives">
                                            Solo activos
                                        </Option>
                                        <Option value="inactives">
                                            Solo inactivos
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={1 / 7}>
                                <Form.Item label="Franquicia" name="franchise">
                                    <Select
                                        maxTagCount="responsive"
                                        onChange={setFranchiseSelected}
                                        style={{ width: '180px' }}>
                                        {franchises?.map((franchise) => (
                                            <Option
                                                value={franchise.id}
                                                key={franchise.id}>
                                                {franchise.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={1 / 7}>
                                <Form.Item label="Sucursal" name="branch">
                                    <Select
                                        maxTagCount="responsive"
                                        style={{ width: '180px' }}>
                                        {branches.length && (
                                            <Option value="all">Todas</Option>
                                        )}
                                        {branches?.map((branch) => (
                                            <Option
                                                value={branch.id}
                                                key={branch.id}>
                                                {branch.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={1 / 7}>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    className={styles.buttonSize}>
                                    Buscar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Row>

                <Row>
                    <Col span={24}>
                        <Spin spinning={loading}>
                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                pagination={{
                                    pageSize,
                                    total,
                                    current: currentPage,
                                    onChange: handlePageChange,
                                }}
                            />
                        </Spin>
                    </Col>
                </Row>
            </Card>

            <ActivatePromotionModal />
            <DeactivatePromotionModal />
        </ABMLayout>
    );
};

export default ListPromsBanners;
