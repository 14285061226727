import React, { useContext, useEffect, useState } from 'react';
import styles from './ProgramPromotions.module.css';
import {
    Card,
    Form,
    Space,
    Typography,
    DatePicker,
    Select,
    Button,
    Row,
    Col,
    Input,
} from 'antd';
import ProgramPromotionsCard from '../../../../atoms/ProgramaRecompensas/Promociones/BannersPromo/ProgramPromotionsCard';
import prefContext from '../../../../../context/preferencias/prefContext';
export { getTheDayFromADate } from '../../../../utils-components/Date';

const ProgramPromotions = () => {
    const { isSidebarAbierto } = useContext(prefContext);
    const [cardsWrapperName, setCardsWrapperName] = useState(
        styles.cardsPromotionTimeWithSidebar,
    );
    const { Title } = Typography;
    const dayOptions = [
        { value: 'everyDay', label: 'Todos los días' },
        { value: 'monday', label: 'Lunes' },
        { value: 'tuesday', label: 'Martes' },
        { value: 'wednesday', label: 'Miércoles' },
        { value: 'thursday', label: 'Jueves' },
        { value: 'friday', label: 'Viernes' },
        { value: 'saturday', label: 'Sábado' },
        { value: 'sunday', label: 'Domingo' },
    ];

    useEffect(() => {
        isSidebarAbierto &&
            setCardsWrapperName(styles.cardsPromotionTimeWithSidebar);

        !isSidebarAbierto &&
            setCardsWrapperName(styles.cardsPromotionTimeWithoutSidebar);
    }, [isSidebarAbierto]);

    const clickHandler = () => {};

    return (
        <Card type="inner" title="Programar promoción" className={styles.card}>
            <Space direction="vertical">
                <Title level={5}>Definí la vigencia de tu promo</Title>
                <Space>
                    <Form.Item label="Fecha de inicio" name="promotionDateFrom">
                        <DatePicker
                            format="DD-MM-YYYY"
                            className={styles.datePicker}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Fecha de finalización"
                        name="promotionDateTo">
                        <DatePicker
                            format="DD-MM-YYYY"
                            className={styles.datePicker}
                        />
                    </Form.Item>
                </Space>

                <Row gutter={5} align="bottom">
                    <Col span={1 / 4}>
                        <Title level={5}>
                            Personalizá días y horarios de la promoción
                        </Title>
                        <Form.Item
                            label="Seleccioná los días de la semana"
                            name="promotionDays">
                            <Select
                                options={dayOptions}
                                className={styles.width290px}
                            />
                        </Form.Item>
                    </Col>

                    <Row gutter={1}>
                        <Form.Item
                            label="Seleccioná el rango de horarios"
                            className={styles.width290px}
                            rules={[{ required: true }]}>
                            <Input.Group className={styles.timePicker}>
                                <Col span={1 / 4}>
                                    <Form.Item name="promotionHourFrom">
                                        <DatePicker
                                            picker="time"
                                            format="hh:mm"
                                            placeholder="Incio"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={1 / 4}>
                                    <Form.Item name="promotionHourTo">
                                        <DatePicker
                                            picker="time"
                                            format="hh:mm"
                                            placeholder="Final"
                                        />
                                    </Form.Item>
                                </Col>
                            </Input.Group>
                        </Form.Item>
                    </Row>

                    <Col span={1 / 4}>
                        <Button
                            onClick={clickHandler}
                            style={{ marginBottom: '24px' }}>
                            Agregar al calendario
                        </Button>
                    </Col>
                </Row>

                <Row className={cardsWrapperName}>
                    <ProgramPromotionsCard
                        day="Domingo"
                        hours={['12:00 hs. - 12:28 hs.']}
                    />
                    <ProgramPromotionsCard day="Lunes" hours={[]} />
                    <ProgramPromotionsCard
                        day="Martes"
                        hours={[
                            '12:00 hs. - 17:27 hs.',
                            '18:03 hs. - 20:00 hs.',
                        ]}
                    />
                    <ProgramPromotionsCard day="Miércoles" hours={[]} />
                    <ProgramPromotionsCard day="Jueves" hours={[]} />
                    <ProgramPromotionsCard
                        day="Viernes"
                        hours={['18:03 hs. - 20:00 hs.']}
                    />
                    <ProgramPromotionsCard day="Sábado" hours={[]} />
                </Row>
            </Space>
        </Card>
    );
};

export default ProgramPromotions;
