import React, { useContext, useEffect, useState } from 'react';
import { Table, Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import ModalContent from '../../../../atoms/ModalContent';
import UsuariosContext from '../../../../../context/usuario/UsuariosContext';
import { Permisos } from '../../../../../constants/Permisos';
import ProtectedComponent from '../../../../../hoc/ProtectedComponent';

const TarjetasUsuario = () => {
    const {
        usuarioSeleccionado,
        obtenerListaTarjetas,
        listaTarjetas,
        loadingListaTarjetas,
        eliminarTarjetaSeleccionada,
        loadingTarjetas,
    } = useContext(UsuariosContext);

    const [isModalEliminarVisible, setModalEliminarVisible] = useState(false);
    const [tarjetaSeleccionada, setTarjetaSeleccionada] = useState();

    const eliminarTarjetaHandler = (record) => {
        setModalEliminarVisible(true);
        setTarjetaSeleccionada(record);
    };

    const confirmarEliminarTarjetaHandler = async () => {
        setModalEliminarVisible(true);
        const tarjetaEliminada = await eliminarTarjetaSeleccionada(
            usuarioSeleccionado.id,
            tarjetaSeleccionada.alias_token,
        );
        setModalEliminarVisible(false);
        tarjetaEliminada && obtenerListaTarjetas(usuarioSeleccionado.id);
    };

    const cancelarEliminarTarjetaHandler = () => {
        setModalEliminarVisible(false);
    };

    const columns = [
        {
            title: 'Tarjeta',
            dataIndex: 'marcaBanco',
            key: 'marcaBanco',
        },
        {
            title: 'Número',
            dataIndex: 'nroTarjeta',
            key: 'nroTarjeta',
            align: 'right',
        },
        {
            title: 'Vencimiento',
            dataIndex: 'vencimiento',
            key: 'vencimiento',
            align: 'right',
        },
        {
            title: 'Tipo',
            dataIndex: 'tipo',
            key: 'tipo',
        },
        {
            key: 'acciones',
            ...ProtectedComponent({
                esObjeto: true,
                permiso:
                    Permisos.usuarios.listaUsuarios.tarjetas.eliminarTarjeta,
                objeto: {
                    title: '',
                    dataIndex: 'acciones',
                    render: (_, record) => (
                        <div style={{ textAlign: 'right' }}>
                            <DeleteOutlined
                                onClick={() => eliminarTarjetaHandler(record)}
                            />
                        </div>
                    ),
                },
            }),
        },
    ];

    useEffect(() => {
        if (usuarioSeleccionado?.id) {
            obtenerListaTarjetas(usuarioSeleccionado.id);
        }
    }, [usuarioSeleccionado]);

    return (
        <>
            <Table
                columns={columns}
                dataSource={listaTarjetas}
                pagination={false}
                loading={loadingListaTarjetas}
            />
            <Modal open={isModalEliminarVisible} footer={null} closable={false}>
                <ModalContent
                    title="Está seguro de que quiere decatastrar esta tarjeta?"
                    okText="Decatastrar"
                    onOk={confirmarEliminarTarjetaHandler}
                    cancelText="Cancelar"
                    onCancel={cancelarEliminarTarjetaHandler}
                    isDelete
                    isLoading={loadingTarjetas}
                />
            </Modal>
        </>
    );
};

export default TarjetasUsuario;
