import React from 'react';
import {
    Card,
    Col,
    Form,
    Input,
    Row,
    Upload,
    Divider,
    Checkbox,
    Space,
    Select,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import styles from './GeneralInfo.module.css';

const GeneralInfo = () => {
    const { Dragger } = Upload;

    return (
        <Card
            type="inner"
            title="Información general"
            className={styles.wrapper}>
            <Row justify="center">
                <Col span={11}>
                    <Form.Item
                        label="Nombre de la promoción"
                        name="promotionName"
                        rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Space>
                        <Form.Item label="Mostrar como" name="showAs">
                            <Select
                                placeholder="Productos"
                                options={[
                                    { label: 'Productos', value: 'products' },
                                    {
                                        label: 'Franquicias',
                                        value: 'franchises',
                                    },
                                ]}
                                className={styles.select}
                            />
                        </Form.Item>
                        <Form.Item className={styles.item} name="showHome">
                            <Checkbox className={styles.checkbox}>
                                Mostrar banner en la Home
                            </Checkbox>
                        </Form.Item>
                    </Space>
                </Col>

                <Col span={2}>
                    <Row justify="center" className={styles.dividerContainer}>
                        <Col>
                            <Divider
                                type="vertical"
                                className={styles.divider}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col span={11}>
                    <Form.Item
                        name="image"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor, sube una imágen',
                            },
                        ]}>
                        <Dragger
                            className={styles.dragger}
                            multiple={false}
                            maxCount={1}
                            // En beforeUpload se le debe pasar una función que
                            // devuelva false para que no se envíe la imagen al
                            // servidor de forma automática
                            beforeUpload={() => false}
                            accept="image/png, image/jpeg, image/jpg">
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                                Hacé clic o arrastrá tu banner acá
                            </p>
                            <p className="ant-upload-hint">
                                El archivo debe ser de 1800x900 px (jpg, jpeg o
                                png)
                            </p>
                        </Dragger>
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    );
};

export default GeneralInfo;
