import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';

import UsuariosContext from './UsuariosContext';
import UsuariosReducer, { initialState } from './UsuariosReducer';

import {
    USUARIOS_GUARDAR_USUARIO_SELECCIONADO,
    USUARIOS_LISTA_TARJETAS,
    USUARIOS_LOADING_LISTA_TARJETAS,
    USUARIOS_LOADING_TARJETA,
    OBTENER_DIRECCION_USUARIO,
    USUARIOS_LOADING_LISTA_DIRECCION,
    USUARIOS_LOADING_EDITAR_DIRECCION,
    USUARIOS_LOADING_NUEVA_DIRECCION,
    USUARIOS_LOADING_ELIMINAR_DIRECCION,
    OBTENER_LISTADO_PEDIDOS,
    USUARIOS_LOADING_LISTA_PEDIDO,
    USUARIOS_GUARDAR_INFO,
    USUARIOS_MODAL_CUPON_CONDICIONES,
    USUARIOS_TAB_SELECCIONADA,
    USUARIOS_PATHNAME,
} from './index';
import axios from '../../config/axios';
import { urls } from '../../constants';
import clienteAxios from '../../config/axios';

const UsuariosState = ({ children }) => {
    const [state, dispatch] = useReducer(UsuariosReducer, initialState);
    const baseURL = urls.REACT_APP_BACKEND_URL_E;
    const baseUrlPedido = urls.REACT_APP_BACKEND_URL_B;

    const abrirModalCondicionesCupones = () => {
        dispatch({
            type: USUARIOS_MODAL_CUPON_CONDICIONES,
        });
    };

    const guardarUsuarioSeleccionado = (usuarioSeleccionado) => {
        dispatch({
            type: USUARIOS_GUARDAR_USUARIO_SELECCIONADO,
            payload: { usuarioSeleccionado: usuarioSeleccionado },
        });

        sessionStorage.setItem('user', JSON.stringify(usuarioSeleccionado));
    };

    const recuperarDatosSessionStorage = () => {
        const usuario = JSON.parse(sessionStorage.getItem('user'));
        dispatch({
            type: USUARIOS_GUARDAR_USUARIO_SELECCIONADO,
            payload: { usuarioSeleccionado: usuario },
        });
    };

    const guardarInfoUsuario = async () => {
        const params = {
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
        };
        try {
            const llamada = await clienteAxios.get(
                `/get_user_by_id/${state.usuarioSeleccionado.id}`,
                { params },
            );
            const userData = llamada.data.data;

            dispatch({
                type: USUARIOS_GUARDAR_INFO,
                payload: { infoUsuario: userData },
            });
        } catch (error) {
            message.error(
                error.response?.data.message ||
                    'Hubo un error al obtener los datos',
            );
        }
    };

    const obtenerListaTarjetas = async (id) => {
        const params = { user_id: id };
        let listaTarjetas = [];

        dispatch({
            type: USUARIOS_LOADING_LISTA_TARJETAS,
        });

        try {
            const respuesta = await axios.get('/user_card_list', { params });
            listaTarjetas = respuesta?.data?.data?.user_cards;
        } catch (error) {
            message.error(error.response.data.message);
            listaTarjetas = [];
        }

        listaTarjetas = listaTarjetas.map((tarjeta) => ({
            ...tarjeta,
            key: tarjeta?.card_id,
            marcaBanco: tarjeta?.card_brand,
            nroTarjeta: tarjeta?.card_masked_number,
            vencimiento: tarjeta?.expiration_date,
            tipo:
                (tarjeta?.card_type === 'credit' && 'Crédito') ||
                (tarjeta?.card_type === 'debit' && 'Débito') ||
                tarjeta?.card_type,
        }));

        dispatch({
            type: USUARIOS_LISTA_TARJETAS,
            payload: {
                listaTarjetas: listaTarjetas,
            },
        });

        dispatch({
            type: USUARIOS_LOADING_LISTA_TARJETAS,
        });
    };

    const eliminarTarjetaSeleccionada = async (userId, token) => {
        const data = {
            user_id: userId,
            alias_token: token,
        };

        let tarjetaEliminada = false;

        dispatch({
            type: USUARIOS_LOADING_TARJETA,
        });

        try {
            await axios.post('/user_card_delete', data);
            tarjetaEliminada = true;
            message.success('Tarjeta decatastrada correctamente');
        } catch (error) {
            message.error(error.response.data.message);
        }

        dispatch({
            type: USUARIOS_LOADING_TARJETA,
        });

        return tarjetaEliminada;
    };

    const obtenerDireccionUsuario = async (idUser) => {
        dispatch({
            type: USUARIOS_LOADING_LISTA_DIRECCION,
        });
        try {
            const { data } = await clienteAxios.get(
                `get_user_addresses/${idUser}`,
                { baseURL },
            );
            dispatch({ type: OBTENER_DIRECCION_USUARIO, payload: data?.data });
        } catch (error) {
            message.error(
                error?.response?.message ||
                    'Hubo un error, vuelva a intentarlo',
            );
        }
        dispatch({
            type: USUARIOS_LOADING_LISTA_DIRECCION,
        });
    };

    const agregarDireccionUsuario = async (nuevaDireccion) => {
        dispatch({
            type: USUARIOS_LOADING_NUEVA_DIRECCION,
        });
        const params = {
            user_id: nuevaDireccion?.id,
            is_default: nuevaDireccion?.principal,
            name: nuevaDireccion?.nombre,
            street1: nuevaDireccion?.callePrincipal,
            street2: nuevaDireccion?.calleSecundaria,
            number: nuevaDireccion?.nroCasa,
            reference: nuevaDireccion?.referencia,
            latitude: nuevaDireccion?.latitud,
            longitude: nuevaDireccion?.longitud,
        };
        let respuesta = false;
        try {
            const { data } = await clienteAxios.post(
                'create_user_address',
                params,
                { baseURL },
            );
            respuesta = true;
            message.success(data?.message);
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un error al crear, vuelva a intentarlo',
            );
        }
        dispatch({
            type: USUARIOS_LOADING_NUEVA_DIRECCION,
        });
        return respuesta;
    };

    const editarDireccionUsuario = async (direccionEditar) => {
        dispatch({
            type: USUARIOS_LOADING_EDITAR_DIRECCION,
        });
        const params = {
            id: direccionEditar?.idDireccion || undefined,
            is_default: direccionEditar?.principal && true,
            name: direccionEditar?.nombre || undefined,
            street1: direccionEditar?.callePrincipal || undefined,
            street2: direccionEditar?.calleSecundaria || undefined,
            number: direccionEditar?.nroCasa || undefined,
            reference: direccionEditar?.referencia || undefined,
            latitude: direccionEditar?.latitud || undefined,
            longitude: direccionEditar?.longitud || undefined,
        };
        let respuesta = false;
        try {
            const { data } = await clienteAxios.put(
                `update_user_address`,
                params,
                { baseURL },
            );
            respuesta = true;
            message.success(data?.message);
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un error al editar, vuelva a intentarlo',
            );
        }
        dispatch({
            type: USUARIOS_LOADING_EDITAR_DIRECCION,
        });
        return respuesta;
    };

    const eliminarDireccionUsuario = async (idDireccion) => {
        dispatch({
            type: USUARIOS_LOADING_ELIMINAR_DIRECCION,
        });
        let respuesta = false;
        try {
            const { data } = await clienteAxios.delete(
                `delete_user_address/${idDireccion}`,
                { baseURL },
            );
            message.success(data?.message);
            respuesta = true;
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un error al eliminar, vuelva a intentarlo',
            );
        }
        dispatch({
            type: USUARIOS_LOADING_ELIMINAR_DIRECCION,
        });
        return respuesta;
    };

    const obtenerListadoPedido = async (params) => {
        const searchParams = {
            date_start: params?.dateStart,
            date_end: params?.dateEnd,
            franchise_id: params?.franchiseId,
            payment_type: params?.paymentType,
            order_states: params?.orderStates,
            delivery_type: params?.deliveryType,
            user_id: state.usuarioSeleccionado.id,
            page: params?.page,
            orders_id: params?.ordersId,
        };

        dispatch({
            type: USUARIOS_LOADING_LISTA_PEDIDO,
        });

        try {
            const response = await clienteAxios.get('get_orders', {
                params: searchParams,
                baseURL: baseUrlPedido,
            });

            const table = {
                rows: response?.data?.data?.orders,
                page: params.page,
                pageSize: response?.data?.data?.items_per_page,
                total: response?.data?.data?.total,
            };

            dispatch({ type: OBTENER_LISTADO_PEDIDOS, payload: { table } });
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un error al obtener listado, vuelva a intentarlo',
            );
        }

        dispatch({
            type: USUARIOS_LOADING_LISTA_PEDIDO,
        });
    };

    const cambiarTabSeleccionada = (tab) => {
        dispatch({
            type: USUARIOS_TAB_SELECCIONADA,
            payload: {
                tabSeleccionada: tab,
            },
        });
    };

    const setPathname = (pathname) => {
        dispatch({
            type: USUARIOS_PATHNAME,
            payload: {
                pathname,
            },
        });
    };

    return (
        <UsuariosContext.Provider
            value={{
                usuarioSeleccionado: state.usuarioSeleccionado,
                direccionUsuario: state.direccionUsuario,
                listaTarjetas: state.listaTarjetas,
                loadingListaTarjetas: state.loadingListaTarjetas,
                loadingTarjetas: state.loadingTarjetas,
                loadingDireccion: state.loadingDireccion,
                loadingDireccionEditar: state.loadingDireccionEditar,
                loadingDireccionNuevo: state.loadingDireccionNuevo,
                loadingDireccionEliminar: state.loadingDireccionEliminar,
                loadingListaPedido: state.loadingListaPedido,
                infoUsuario: state.infoUsuario,
                isModalVisible: state.modalCondicionesVisible,
                tabSeleccionada: state.tabSeleccionada,
                pathname: state.pathname,

                ordersTable: state.ordersTable,

                guardarUsuarioSeleccionado,
                obtenerListaTarjetas,
                eliminarTarjetaSeleccionada,
                obtenerDireccionUsuario,
                agregarDireccionUsuario,
                editarDireccionUsuario,
                eliminarDireccionUsuario,
                obtenerListadoPedido,

                guardarInfoUsuario,
                recuperarDatosSessionStorage,

                abrirModalCondicionesCupones,

                cambiarTabSeleccionada,

                setPathname,
            }}>
            {children}
        </UsuariosContext.Provider>
    );
};

export default UsuariosState;

UsuariosState.propTypes = {
    children: PropTypes.node,
};
