import { useEffect, useState, useContext } from 'react';
import PromotionsContext from '../../context/programaRecompensa/Promociones/PromotionsContext';
import UtilidadesContext from '../../context/utilidades/UtilidadesContext';

const useNewPromBanner = () => {
    const { obtenerFamilias, obtenerFranquicias, obtenerSucursales } =
        useContext(UtilidadesContext);
    const { getAudience, audienceList } = useContext(PromotionsContext);
    const [families, setFamilies] = useState([]);
    const [franchises, setFranchises] = useState([]);
    const [branches, setBranches] = useState([]);
    const [obtainingBranches, setObtainingBranches] = useState(false);
    const [audience, setAudience] = useState([]);

    useEffect(() => {
        obtenerFamilias().then((response) => setFamilies(response));
        obtenerFranquicias().then((response) => setFranchises(response));
        getAudience();
    }, []);

    useEffect(() => setAudience(audienceList), [audienceList]);

    const getBranches = async (id) => {
        setObtainingBranches(true);
        const branches = await obtenerSucursales(id);
        setBranches(branches);
        setObtainingBranches(false);
    };

    return {
        families,
        franchises,
        branches,
        obtainingBranches,
        audience,

        getBranches,
        setObtainingBranches,
    };
};

export default useNewPromBanner;
