import {
    PROMOTIONS_LIST,
    PROMOTION_NEW_OR_EDIT,
    PROMOTION_ID,
    PROMOTION_ENABLE,
    PROMOTION_SHOW_DEACTIVATE_MODAL,
    PROMOTION_SHOW_ACTIVATE_MODAL,
    PROMOTION_CURRENT_PAGE,
    PROMOTION_FILTERS_TO_LIST,
    PROMOTION_SHOW_ADD_PRODUCT_MODAL,
    PROMOTION_SELECTED_PRODUCTS,
    PROMOTION_FRANCHISES_SELECTED,
    PROMOTION_BRANCHES_SELECTED,
    PROMOTION_PRODUCT_LIST,
    PROMOTION_AUDIENCE_LIST,
} from './Types';

export const initialState = {
    promotionsList: [],
    newOrEditProm: '',
    id: null,
    enable: null,
    showDeactivatePromotionModal: false,
    showActivatePromotionModal: false,
    filters: null,
    showAddProductModal: false,
    selectedProducts: [],
    selectedFranchises: [],
    selectedBranches: [],
    productList: [],
    audienceList: [],
};

const PromotionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PROMOTIONS_LIST:
            return {
                ...state,
                promotionsList: action.payload.promotionsList,
            };

        case PROMOTION_NEW_OR_EDIT:
            return {
                ...state,
                newOrEditProm: action.payload.newOrEditProm,
            };

        case PROMOTION_ID:
            return {
                ...state,
                id: action.payload.id,
            };

        case PROMOTION_ENABLE:
            return {
                ...state,
                enable: action.payload.enable,
            };

        case PROMOTION_SHOW_DEACTIVATE_MODAL:
            return {
                ...state,
                showDeactivatePromotionModal:
                    action.payload.showDeactivatePromotionModal,
            };

        case PROMOTION_SHOW_ACTIVATE_MODAL:
            return {
                ...state,
                showActivatePromotionModal:
                    action.payload.showActivatePromotionModal,
            };

        case PROMOTION_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload.currentPage,
            };

        case PROMOTION_FILTERS_TO_LIST:
            return {
                ...state,
                filters: action.payload.filters,
            };

        case PROMOTION_SHOW_ADD_PRODUCT_MODAL:
            return {
                ...state,
                showAddProductModal: action.payload.showAddProductModal,
            };

        case PROMOTION_SELECTED_PRODUCTS:
            return {
                ...state,
                selectedProducts: action.payload.selectedProducts,
            };

        case PROMOTION_FRANCHISES_SELECTED:
            return {
                ...state,
                selectedFranchises: action.payload.selectedFranchises,
            };

        case PROMOTION_BRANCHES_SELECTED:
            return {
                ...state,
                selectedBranches: action.payload.selectedBranches,
            };

        case PROMOTION_PRODUCT_LIST:
            return {
                ...state,
                productList: action.payload.productList,
            };

        case PROMOTION_AUDIENCE_LIST:
            return {
                ...state,
                audienceList: action.payload.audienceList,
            };

        default:
            return state;
    }
};

export default PromotionsReducer;
