import {
    BorderlessTableOutlined,
    BarcodeOutlined,
    DashboardOutlined,
    CreditCardOutlined,
    FundOutlined,
    CalendarOutlined,
    MobileOutlined,
    UserOutlined,
    UnorderedListOutlined,
    WalletOutlined,
    CustomerServiceOutlined,
    FileDoneOutlined,
    PictureOutlined,
    TeamOutlined,
    ShopOutlined,
    SyncOutlined,
    PartitionOutlined,
    GoldOutlined,
    NotificationOutlined,
    CopyOutlined,
    TagOutlined,
} from '@ant-design/icons';
import { ambientes } from '../constants';
import { Permisos } from '../constants/Permisos';
import acortarTexto from '../components/utils-components/acortarTexto';

const panelNavTree = [
    {
        path: `/panel`,
        title: 'Panel',
        icon: DashboardOutlined,
        deployTo: ambientes.prod,
        permission: Permisos.panel,
        submenu: [],
    },
];

const finanzasNavTree = [
    {
        path: `/finanzas`,
        title: 'Finanzas',
        icon: '',
        deployTo: ambientes.prod,
        permission: Permisos.finanzas.verFinanzas,
        submenu: [
            {
                path: `/finanzas/facturacion`,
                title: 'Facturación',
                icon: FileDoneOutlined,
                deployTo: ambientes.prod,
                permission: Permisos.finanzas.facturacion.verFacturacion,
                submenu: [
                    {
                        path: `/finanzas/facturacion/balance`,
                        title: 'Balance',
                        icon: '',
                        deployTo: ambientes.prod,
                        permission:
                            Permisos.finanzas.facturacion.balance.verPagina,
                        submenu: [],
                    },
                    {
                        path: `/finanzas/facturacion/emision-documentos/lista`,
                        title: 'Emisión de documentos',
                        icon: '',
                        deployTo: ambientes.prod,
                        permission:
                            Permisos.finanzas.facturacion.emisionDocumentos
                                .verPagina,
                        submenu: [],
                    },
                    {
                        path: `/finanzas/facturacion/timbrado/lista`,
                        title: 'Timbrado',
                        icon: '',
                        deployTo: ambientes.prod,
                        permission: Permisos.finanzas.facturacion.timbrado,
                        submenu: [],
                    },
                    {
                        path: `/finanzas/facturacion/documentos-comercio/lista`,
                        title: 'Mis documentos',
                        icon: '',
                        deployTo: ambientes.prod,
                        permission: Permisos.finanzas.facturacion.misDocumentos,
                        submenu: [],
                    },
                    {
                        path: `/finanzas/facturacion/recibos/lista`,
                        title: 'Lista de recibos',
                        icon: '',
                        deployTo: ambientes.prod,
                        permission: Permisos.finanzas.facturacion.listaRecibos,
                        submenu: [],
                    },
                ],
            },
        ],
    },
];

const reportesNavTree = [
    {
        path: `/reportes`,
        title: 'Reportes',
        icon: '',
        deployTo: ambientes.prod,
        permission: Permisos.reportes.verReportes,
        submenu: [
            {
                path: `/reportes/ventas`,
                title: 'Ventas',
                icon: FundOutlined,
                deployTo: ambientes.prod,
                permission: Permisos.reportes.ventas.verVentas,
                submenu: [
                    {
                        path: `/reportes/ventas/detalle-transaccion/lista`,
                        title: 'Detalle de transacción',
                        icon: '',
                        deployTo: ambientes.prod,
                        permission:
                            Permisos.reportes.ventas.detalleTransaccion
                                .verPagina,
                        submenu: [],
                    },
                    {
                        path: `/reportes/ventas/reporte-balance`,
                        title: 'Reporte de balance',
                        icon: '',
                        deployTo: ambientes.prod,
                        permission:
                            Permisos.reportes.ventas.reporteBalance.verPagina,
                        submenu: [],
                    },
                    {
                        path: `/reportes/ventas/reporte-comisiones/lista`,
                        title: 'Reporte de comisiones',
                        icon: '',
                        deployTo: ambientes.prod,
                        permission:
                            Permisos.reportes.ventas.reporteComisiones
                                .verPagina,
                        submenu: [],
                    },
                ],
            },
            {
                path: `/reportes/cortes`,
                title: 'Cortes',
                icon: CalendarOutlined,
                deployTo: ambientes.prod,
                permission: Permisos.reportes.cortes.verCortes,
                submenu: [
                    {
                        path: `/reportes/cortes/corte-transaccion`,
                        title: 'Corte de transacción',
                        icon: '',
                        deployTo: ambientes.prod,
                        permission: Permisos.reportes.cortes.corteTransaccion,
                        submenu: [],
                    },
                ],
            },
            {
                path: `/reportes/programa-recompensa`,
                title: acortarTexto('Programa Recompensa'),
                icon: WalletOutlined,
                deployTo: ambientes.prod,
                permission:
                    Permisos.reportes.programaRecompensa.verReporteCupones,
                submenu: [
                    {
                        path: `/reportes/programa-recompensa/cupones/lista`,
                        title: 'Reporte de cupones',
                        icon: '',
                        deployTo: ambientes.prod,
                        permission:
                            Permisos.reportes.programaRecompensa
                                .verReporteCupones,
                        submenu: [],
                    },
                ],
            },
            {
                path: `/reportes/ordenes`,
                title: 'Órdenes',
                icon: CopyOutlined,
                deployTo: ambientes.prod,
                permission: Permisos.reportes.verReportes,
                submenu: [],
            },
        ],
    },
];

const pushNavTree = [
    {
        path: `/push`,
        title: 'Push',
        icon: '',
        deployTo: ambientes.prod,
        permission: Permisos.push.verPush,
        submenu: [
            {
                path: `/push/enviar-push`,
                title: 'Enviar Push',
                icon: MobileOutlined,
                deployTo: ambientes.prod,
                permission: Permisos.push.enviarPush,
                submenu: [],
            },
            {
                path: `/push/lista-push`,
                title: 'Lista Push',
                icon: UnorderedListOutlined,
                deployTo: ambientes.prod,
                permission: Permisos.push.listaPush,
                submenu: [],
            },
        ],
    },
];

const programaRecompensaNavTree = [
    {
        path: `/programa-recompensa`,
        title: 'Programa Recompensa',
        icon: '',
        deployTo: ambientes.prod,
        permission: Permisos.programaRecompensa.verProgramaRecompensa,
        submenu: [
            {
                path: `/programa-recompensa/promociones/banners-promo`,
                title: 'Promociones',
                icon: NotificationOutlined,
                deployTo: ambientes.dev,
                permission:
                    Permisos.programaRecompensa.promociones.listarBanners,
                submenu: [
                    {
                        path: `/programa-recompensa/promociones/banners-promo/`,
                        title: 'Banners promo',
                        icon: '',
                        deployTo: ambientes.dev,
                        permission:
                            Permisos.programaRecompensa.promociones
                                .listarBanners,
                        submenu: [],
                    },
                ],
            },
            {
                path: `/programa-recompensa/cupones/lista`,
                title: 'Cupones',
                icon: BarcodeOutlined,
                deployTo: ambientes.prod,
                permission: Permisos.programaRecompensa.cupones.listaCupones,
                submenu: [],
            },
            {
                path: `/programa-recompensa/temporada/lista`,
                title: 'Temporada',
                icon: PictureOutlined,
                deployTo: ambientes.prod,
                permission:
                    Permisos.programaRecompensa.temporadas.listarTemporadas,
                submenu: [],
            },
            {
                path: `/programa-recompensa/nivel-usuario/lista`,
                title: 'Niveles de usuario',
                icon: BorderlessTableOutlined,
                deployTo: ambientes.prod,
                permission:
                    Permisos.programaRecompensa.nivelUsuario.listaNiveles,
                submenu: [],
            },
        ],
    },
];

const pagoOnlineNavTree = [
    {
        path: `/pago-online`,
        title: 'Pago Online',
        icon: '',
        deployTo: ambientes.dev,
        permission: Permisos.pagoOnline.tarjetas.listaUsuarios,
        submenu: [
            {
                path: `/pago-online/tarjetas`,
                title: 'Tarjetas',
                icon: CreditCardOutlined,
                deployTo: ambientes.dev,
                permission: Permisos.pagoOnline.tarjetas.listaUsuarios,
                submenu: [
                    {
                        path: `/pago-online/tarjetas/lista-usuarios`,
                        title: 'Lista de usuarios',
                        icon: '',
                        deployTo: ambientes.dev,
                        permission: Permisos.pagoOnline.tarjetas.listaUsuarios,
                        submenu: [],
                    },
                ],
            },
        ],
    },
];

const UsuariosNavTree = [
    {
        path: `/usuarios`,
        title: 'Usuarios',
        icon: '',
        deployTo: ambientes.prod,
        permission: Permisos.usuarios.verUsuarios,
        submenu: [
            {
                path: `/usuarios/lista`,
                title: 'Lista de usuarios',
                icon: UserOutlined,
                deployTo: ambientes.prod,
                permission: Permisos.usuarios.listaUsuarios.verPagina,
                submenu: [],
            },
            {
                path: `/usuarios/clientes/lista`,
                title: 'Usuarios pendientes',
                icon: TeamOutlined,
                deployTo: ambientes.prod,
                permission: Permisos.usuarios.clientes.verPagina,
                submenu: [],
            },
        ],
    },
];

const AdministrarNavTree = [
    {
        path: `/administrar`,
        title: 'Administrar',
        icon: '',
        deployTo: ambientes.prod,
        permission: Permisos.administrar.verAdministrar,
        submenu: [
            {
                path: `/administrar/franquicias/lista-franquicias`,
                title: 'Franquicias',
                icon: PartitionOutlined,
                deployTo: ambientes.prod,
                permission: Permisos.administrar.franquicias.verPagina,
                submenu: [],
            },
            {
                path: `/administrar/marcas`,
                title: 'Marcas',
                icon: ShopOutlined,
                deployTo: ambientes.prod,
                permission: Permisos.administrar.franquicias.verPagina,
                submenu: [],
            },
            {
                path: `/administrar/catastros/lista`,
                title: 'Catastros',
                icon: UnorderedListOutlined,
                deployTo: ambientes.prod,
                permission: Permisos.administrar.catastros.verPagina,
                submenu: [],
            },
            {
                path: `/administrar/productos/actualizar`,
                title: 'Productos',
                icon: GoldOutlined,
                deployTo: ambientes.prod,
                permission: Permisos.administrar.franquicias.verPagina, // TO-DO: Revisar este permiso
                submenu: [],
            },
            {
                path: `/administrar/integraciones`,
                title: 'Integraciones',
                icon: SyncOutlined,
                deployTo: ambientes.prod,
                permission: Permisos.administrar.franquicias.verPagina,
                submenu: [
                    {
                        path: `/administrar/integraciones/sucursales`,
                        title: 'Sucursales',
                        icon: '',
                        deployTo: ambientes.dev,
                        permission: Permisos.pagoOnline.tarjetas.listaUsuarios,
                        submenu: [],
                    },
                    {
                        path: `/administrar/integraciones/productos`,
                        title: 'Productos',
                        icon: '',
                        deployTo: ambientes.dev,
                        permission: Permisos.pagoOnline.tarjetas.listaUsuarios,
                        submenu: [],
                    },
                    {
                        path: `/administrar/integraciones/atributos`,
                        title: 'Atributos',
                        icon: '',
                        deployTo: ambientes.dev,
                        permission: Permisos.pagoOnline.tarjetas.listaUsuarios,
                        submenu: [],
                    },
                ],
            },
        ],
    },
];

const SupportNavTree = [
    {
        path: `/support/perfiles`,
        title: 'Support',
        icon: '',
        deployTo: ambientes.prod,
        permission: Permisos.support.verSupport,
        submenu: [
            {
                path: `/support/perfiles`,
                title: 'Support',
                icon: CustomerServiceOutlined,
                deployTo: ambientes.prod,
                permission: Permisos.support.verSupport,
                submenu: [],
            },
        ],
    },
];

// Array de todos los menus de navegacion
const arrMenuNavegacion = [
    ...panelNavTree,
    ...finanzasNavTree,
    ...reportesNavTree,
    ...pushNavTree,
    ...programaRecompensaNavTree,
    ...pagoOnlineNavTree,
    ...UsuariosNavTree,
    ...AdministrarNavTree,
    ...SupportNavTree,
];

// Obtener el ambiente actual PROD || DEV
const ambienteActual = process.env.REACT_APP_NODE_ENV
    ? ambientes.dev
    : ambientes.prod;

// Configuracion del menu de navegacion
let navegacionConfig = arrMenuNavegacion;

// Filtrar ambiente de deploy para el menu de nivel 1
if (ambienteActual === ambientes.prod)
    navegacionConfig = navegacionConfig.filter(
        (menuItem) => menuItem.deployTo === ambienteActual,
    );

// Filtrar ambiente de deploy para el menu de nivel 2
if (ambienteActual === ambientes.prod) {
    navegacionConfig = navegacionConfig?.map((menuNivel1) => ({
        ...menuNivel1,
        submenu: menuNivel1?.submenu?.length
            ? menuNivel1.submenu.filter(
                  (menuNivel2) => menuNivel2.deployTo === ambienteActual,
              )
            : [],
    }));
}

// Filtrar ambiente de deploy para el menu de nivel 3
if (ambienteActual === ambientes.prod) {
    navegacionConfig = navegacionConfig?.map((menuNivel1) => ({
        ...menuNivel1,
        submenu: menuNivel1.submenu?.map((menuNivel2) => ({
            ...menuNivel2,
            submenu: menuNivel2?.submenu?.length
                ? menuNivel2.submenu.filter(
                      (menuNivel3) => menuNivel3.deployTo === ambienteActual,
                  )
                : [],
        })),
    }));
}

export default navegacionConfig;
