export const PROMOTIONS_LIST = 'PROMOTIONS_LIST';
export const PROMOTION_NEW_OR_EDIT = 'PROMOTION_NEW_OR_EDIT';
export const PROMOTION_ID = 'PROMOTION_ID';
export const PROMOTION_ENABLE = 'PROMOTION_ENABLE';
export const PROMOTION_SHOW_DEACTIVATE_MODAL =
    'PROMOTION_SHOW_DEACTIVATE_MODAL';
export const PROMOTION_SHOW_ACTIVATE_MODAL = 'PROMOTION_SHOW_ACTIVATE_MODAL';
export const PROMOTION_CURRENT_PAGE = 'PROMOTION_CURRENT_PAGE';
export const PROMOTION_FILTERS_TO_LIST = 'PROMOTION_FILTERS_TO_LIST';
export const PROMOTION_SHOW_ADD_PRODUCT_MODAL =
    'PROMOTION_SHOW_ADD_PRODUCT_MODAL';
export const PROMOTION_SELECTED_PRODUCTS = 'PROMOTION_SELECTED_PRODUCTS';
export const PROMOTION_FRANCHISES_SELECTED = 'PROMOTION_FRANCHISES_SELECTED';
export const PROMOTION_BRANCHES_SELECTED = 'PROMOTION_BRANCHES_SELECTED';
export const PROMOTION_PRODUCT_LIST = 'PROMOTION_PRODUCT_LIST';
export const PROMOTION_AUDIENCE_LIST = 'PROMOTION_AUDIENCE_LIST';
