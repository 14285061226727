import React, { useReducer } from 'react';
import PromotionsReducer, { initialState } from './PromotionsReducer';
import PromotionsContext from './PromotionsContext';
import { urls } from '../../../constants';
import clienteAxios from '../../../config/axios';
import PropTypes from 'prop-types';
import { message } from 'antd';
import getErrorMessage from '../../../components/utils-components/getErrorMessage';

import {
    PROMOTIONS_LIST,
    PROMOTION_NEW_OR_EDIT,
    PROMOTION_ID,
    PROMOTION_ENABLE,
    PROMOTION_SHOW_DEACTIVATE_MODAL,
    PROMOTION_SHOW_ACTIVATE_MODAL,
    PROMOTION_CURRENT_PAGE,
    PROMOTION_FILTERS_TO_LIST,
    PROMOTION_SHOW_ADD_PRODUCT_MODAL,
    PROMOTION_SELECTED_PRODUCTS,
    PROMOTION_FRANCHISES_SELECTED,
    PROMOTION_BRANCHES_SELECTED,
    PROMOTION_PRODUCT_LIST,
    PROMOTION_AUDIENCE_LIST,
} from './Types';

const PromotionsState = ({ children }) => {
    const [state, dispatch] = useReducer(PromotionsReducer, initialState);

    /**
     * getPromotionsList
     * @param {String} name
     * @param {Boolean} enabled
     * @param {Number} page
     */
    const getPromotionsList = async (
        name = undefined,
        enabled = undefined,
        franchise = undefined,
        branch = undefined,
        page = 1,
    ) => {
        try {
            const params = {
                name,
                page,
                enabled,
                franchise_id: franchise,
                branch_id: branch,

                axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
            };

            const promotionsList = await clienteAxios.get('promotion_list', {
                params,
            });

            if (!promotionsList?.data?.success)
                throw new Error(
                    promotionsList?.message ||
                        'No hemos podido obtener la lista de promociones.',
                );

            dispatch({
                type: PROMOTIONS_LIST,
                payload: {
                    promotionsList: promotionsList?.data?.data,
                },
            });
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            message.error(errorMessage);
        }
    };

    /**
     * activatePromotion
     * @param {Boolean} enabled
     * @param {Number} id
     */
    const activatePromotion = async (enabled, id) => {
        const data = {
            enabled,

            axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
        };

        try {
            const response = await clienteAxios.patch(
                `/promotion_activation/${id}`,
                data,
            );

            if (response?.data?.success)
                message.success(
                    response?.data?.message ||
                        'Promoción desactivada con éxito',
                );
            else throw new Error('No hemos podido desactivar la promoción');
        } catch (error) {
            const errorMessage = getErrorMessage(
                error,
                'No hemos podido desactivar la promoción',
            );
            message.error(errorMessage);
        }
    };

    const getProductsList = async (
        productName,
        franchiseIds,
        branchIds,
        page = undefined,
        enabled = true,
        disabled = true,
        deleted = false,
        orderBy = 'updated_at',
        order = 'DESC',
    ) => {
        const params = {
            product_name: productName,
            franchise_ids: franchiseIds,
            branch_ids: branchIds,
            page,
            enabled,
            disabled,
            deleted,
            order_by: orderBy,
            order,

            axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
        };

        try {
            const response = await clienteAxios.get('products', { params });

            if (!response?.success)
                throw new Error(
                    'No hemos podido obtener la lista de productos',
                );

            dispatch({
                type: PROMOTION_PRODUCT_LIST,
                payload: {
                    productList: response?.data?.data?.products_updated,
                },
            });
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            message.error(errorMessage);
        }
    };

    const getAudience = async () => {
        const params = {
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
        };

        try {
            const response = await clienteAxios.get('get_audiencies', {
                params,
            });

            if (!response?.data?.success)
                throw new Error(
                    'No hemos podido obtener el listado de audiencias',
                );

            const audience = response?.data?.data?.audiencies;

            dispatch({
                type: PROMOTION_AUDIENCE_LIST,
                payload: {
                    audienceList: audience,
                },
            });
        } catch (error) {
            const errorMessage = getErrorMessage(
                error,
                'No hemos podido obtener el listado de audiencias',
            );
            message.error(errorMessage);
        }
    };

    const setNewOrEditProm = (newOrEditProm) => {
        dispatch({
            type: PROMOTION_NEW_OR_EDIT,
            payload: {
                newOrEditProm,
            },
        });
    };

    const setId = (id) => {
        dispatch({
            type: PROMOTION_ID,
            payload: {
                id,
            },
        });
    };

    const setEnable = (enable) => {
        dispatch({
            type: PROMOTION_ENABLE,
            payload: {
                enable,
            },
        });
    };

    const setShowDeactivatePromotionModal = (show) => {
        dispatch({
            type: PROMOTION_SHOW_DEACTIVATE_MODAL,
            payload: {
                showDeactivatePromotionModal: show,
            },
        });
    };

    const setShowActivatePromotionModal = (show) => {
        dispatch({
            type: PROMOTION_SHOW_ACTIVATE_MODAL,
            payload: {
                showActivatePromotionModal: show,
            },
        });
    };

    const setCurrentPage = (currentPage) => {
        dispatch({
            type: PROMOTION_CURRENT_PAGE,
            payload: {
                currentPage,
            },
        });
    };

    const setFilters = (filters) => {
        dispatch({
            type: PROMOTION_FILTERS_TO_LIST,
            payload: {
                filters,
            },
        });
    };

    const setShowAddProductModal = (show) => {
        dispatch({
            type: PROMOTION_SHOW_ADD_PRODUCT_MODAL,
            payload: {
                showAddProductModal: show,
            },
        });
    };

    const setSelectedProducts = (selectedProducts) => {
        dispatch({
            type: PROMOTION_SELECTED_PRODUCTS,
            payload: {
                selectedProducts,
            },
        });
    };

    const setSelectedFranchises = (franchises) => {
        dispatch({
            type: PROMOTION_FRANCHISES_SELECTED,
            payload: {
                selectedFranchises: franchises,
            },
        });
    };

    const setSelectedBranches = (branches) => {
        dispatch({
            type: PROMOTION_BRANCHES_SELECTED,
            payload: {
                selectedBranches: branches,
            },
        });
    };

    return (
        <PromotionsContext.Provider
            value={{
                promotionsList: state.promotionsList,
                newOrEditProm: state.newOrEditProm,
                id: state.id,
                enable: state.enable,
                showDeactivatePromotionModal:
                    state.showDeactivatePromotionModal,
                showActivatePromotionModal: state.showActivatePromotionModal,
                currentPage: state.currentPage,
                filters: state.filters,
                showAddProductModal: state.showAddProductModal,
                selectedProducts: state.selectedProducts,
                selectedFranchises: state.selectedFranchises,
                selectedBranches: state.selectedBranches,
                productList: state.productList,
                audienceList: state.audienceList,

                getPromotionsList,
                setNewOrEditProm,
                activatePromotion,
                setId,
                setEnable,
                setShowDeactivatePromotionModal,
                setShowActivatePromotionModal,
                setCurrentPage,
                setFilters,
                setShowAddProductModal,
                setSelectedProducts,
                setSelectedFranchises,
                setSelectedBranches,
                getProductsList,
                getAudience,
            }}>
            {children}
        </PromotionsContext.Provider>
    );
};

PromotionsState.propTypes = {
    children: PropTypes.node,
};

export default PromotionsState;
