import React, { useState, useEffect } from 'react';
import { Col, Select, message, Button, Row, Table, Input, Form } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import SinDatosPlaceholder from '../SinDatosPlaceholder';
import axios from '../../../config/axios';
import { urls } from '../../../constants';
import PropTypes from 'prop-types';

const SelectZonaCobertura = ({
    pushForm,
    fields,
    setCantidadUsuariosSeleccionados,
    setRestablecerDeeplink,
    setAlcanceLoading,
}) => {
    // States para "franquicias"
    const [franquiciasValue, setFranquiciasValue] = useState('');
    const [franquiciasData, setFranquiciasData] = useState([]);
    const [franquiciasDisabled, setFranquiciasDisabled] = useState(true);
    const [franquiciasLoading, setFranquiciasLoading] = useState(false);
    const [franquiciasRequired, setFranquiciasRequired] = useState(false);

    // States para "sucursales"
    const [sucursalesValue, setSucursalesValue] = useState('');
    const [sucursalesData, setSucursalesData] = useState([]);
    const [sucursalesDisabled, setSucursalesDisabled] = useState(true);
    const [sucursalesLoading, setSucursalesLoading] = useState(false);

    // States para "zonas"
    const [zonasValue, setZonasValue] = useState('');
    const [zonasData, setZonasData] = useState([]);
    const [zonasDisabled, setZonasDisabled] = useState(true);
    const [zonasLoading, setZonasLoading] = useState(false);
    const [zonasRequired, setZonasRequired] = useState(false);

    const [listaZonasCoberturaData, setListaZonasCoberturaData] = useState([]);
    const [agregarButtonDisabled, setAgregarButtonDisabled] = useState(false);
    const [agregarButtonLoading, setAgregarButtonLoading] = useState(false);

    // Obtener datos de "franquicias" del "endpoint"
    const obtenerFranquicias = async () => {
        let arrFranquicias = [];
        setFranquiciasLoading(true);
        try {
            const respuesta = await axios.get('/franchises_list');
            respuesta.data.data.franchises.map((item) =>
                arrFranquicias.push(item),
            );
        } catch (error) {
            message.error(
                'Hubo un problema al obtener los datos de las franquicias',
            );
            console.log(
                'Hubo un problema al obtener los datos de las franquicias',
                error,
            );
        }
        setFranquiciasLoading(false);

        setFranquiciasData(arrFranquicias);
        setFranquiciasDisabled(!arrFranquicias.length > 0);
        setFranquiciasRequired(arrFranquicias.length > 0);
    };

    function handleChangeFranquicias(value) {
        setFranquiciasValue(value);
        if (value && Number(value)) {
            setSucursalesValue('');
            setZonasValue('');
            setZonasDisabled(true);
            setZonasRequired(false);
            obtenerSucursales(value);
        } else {
            setSucursalesDisabled(true);
        }
    }

    // Obtener datos de "sucursales" del "endpoint"
    const obtenerSucursales = async (franchiseId) => {
        let arrSucursales = [];

        setSucursalesLoading(true);
        try {
            const respuesta = await axios.get('/branches_list', {
                params: { franchise_id: franchiseId },
            });
            arrSucursales = respuesta.data.data.branches.map((item) => ({
                id: item.branch_id,
                name: item.name,
            }));
        } catch (error) {
            message.error(
                'Hubo un problema al obtener los datos de las sucursales',
            );
            console.log(
                'Hubo un problema al obtener los datos de las sucursales',
                error,
            );
        }
        setSucursalesLoading(false);

        setSucursalesData(arrSucursales);
        setSucursalesDisabled(!arrSucursales.length > 0);
    };

    function handleChangeSucursales(value) {
        setSucursalesValue(value);
        if (value && Number(value)) {
            obtenerZonas(value);
            setZonasValue('');
        } else {
            setZonasDisabled(true);
        }
    }

    // Obtener datos de "zonas" del "endpoint"
    const obtenerZonas = async (branchId) => {
        let arrZonas = [];
        setZonasLoading(true);
        try {
            const respuesta = await axios.get('/delivery_zone_names', {
                params: { branch_id: branchId },
            });
            arrZonas = respuesta.data.data.delivery_zones.map((item) => ({
                id: item.zone_id,
                name: item.zone_name,
            }));
        } catch (error) {
            message.error('Hubo un problema al obtener los datos de las zonas');
            console.log(
                'Hubo un problema al obtener los datos de las zonas',
                error,
            );
        }
        setZonasLoading(false);

        setZonasData(arrZonas);
        setZonasDisabled(!arrZonas.length > 0);
        setZonasRequired(arrZonas.length > 0);
    };

    const handleChangeZonas = (value) => {
        setZonasValue(value);
    };

    // Agregar zona a la tabla
    const agregarZonaHandler = async () => {
        let franquicia = {},
            sucursal = {},
            zona = {};
        setAgregarButtonLoading(true);
        setFranquiciasDisabled(true);
        setSucursalesDisabled(true);
        setZonasDisabled(true);

        if (franquiciasRequired && franquiciasValue) {
            franquicia = franquiciasData.find(
                (item) => item.id === franquiciasValue,
            );

            if (zonasRequired && zonasValue) {
                sucursal = sucursalesData.find(
                    (item) => item.id === sucursalesValue,
                );
                zona = zonasData.find((item) => item.id === zonasValue);
            } else if (zonasRequired && !zonasValue) {
                setAgregarButtonLoading(false);
                setFranquiciasDisabled(false);
                setSucursalesDisabled(false);
                setZonasDisabled(false);
                message.error(
                    'Debe completar todos los campos obligatorios para agregar la zona',
                );
                return;
            }

            // No agregar si:
            // * El usuario selecciona "franquicia, sucursal, zona"
            // * Y ya existe la misma seleccion en la lista
            let condition1 = true;
            if (franquiciasValue && sucursalesValue && zonasValue) {
                let result = listaZonasCoberturaData.findIndex(
                    (item) =>
                        item.franquiciaId === franquicia.id &&
                        item.sucursalId === sucursal.id &&
                        item.zonaId === zona.id,
                );
                condition1 = result === -1 ? true : false;
            }
            // * El usuario selecciona "franquicia"
            // * Y ya existe una "franquicia" sin "sucursales, zonas" similar
            // * "Franquicia" sin "sucursal, zona" cubre mayor cobertura que una zona especifica
            let condition2 = true;
            if (franquiciasValue) {
                const result = listaZonasCoberturaData.findIndex(
                    (item) =>
                        item.franquiciaId === franquiciasValue &&
                        !item.sucursalId &&
                        !item.zonaId,
                );
                condition2 = result === -1 ? true : false;
            }
            // * El usuario selecciona "franquicia"
            // * Y ya existe una "franquicia, sucursal, zona" similar
            let condition3 = true;
            if (franquiciasValue && !zonasValue) {
                const result = listaZonasCoberturaData.findIndex(
                    (item) =>
                        item.franquiciaId === franquiciasValue &&
                        item.sucursalId &&
                        item.zonaId,
                );
                condition3 = result === -1 ? true : false;
            }

            if (condition1 && condition2 && condition3) {
                // Guardar seleccion en el state
                setListaZonasCoberturaData((prevState) => [
                    ...prevState,
                    {
                        key: Math.random(),
                        franquicia: franquicia?.name,
                        franquiciaId: franquicia?.id,
                        sucursal: sucursal?.name,
                        sucursalId: sucursal?.id,
                        zona: zona?.name,
                        zonaId: zona?.id,
                    },
                ]);

                // Restablecer selects de "franquicia, sucursal, zona"
                setFranquiciasValue('');
                setSucursalesValue('');
                setSucursalesData([]);
                setSucursalesDisabled(true);
                setZonasValue('');
                setZonasData([]);
                setZonasDisabled(true);
                setZonasRequired(false);
            } else {
                message.info('La zona que quiere agregar ya está en la lista');
            }
        } else {
            message.error(
                'Debe completar todos los campos obligatorios para agregar la zona',
            );
        }
        setAgregarButtonLoading(false);
        setFranquiciasDisabled(false);
        setSucursalesDisabled(false);
        setZonasDisabled(false);
    };

    // Remover zona de la tabla
    const removerZonaHandler = (record) => {
        setListaZonasCoberturaData(
            listaZonasCoberturaData.filter((r) => r !== record),
        );
    };

    // Obtener la cantidad de usuarios que existen en la zona especificada
    const obtenerCantidadUsuarios = async (zonasCobertura) => {
        let cantidadUsuarios = 0;
        setAlcanceLoading(true);

        try {
            const data = {
                axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
                filters: zonasCobertura,
            };

            const respuesta = await axios.post('/users_by_zones', data);

            // El objeto "users" es un array con dos objetos que retorna la
            // cantidad de usuarios por tipo de sistema operativo.
            respuesta.data.data.users.forEach((item) => {
                try {
                    cantidadUsuarios += Number(item.count);
                } catch (e) {
                    cantidadUsuarios += 0;
                }
            });
        } catch (error) {
            message.error(
                'Hubo un problema al obtener el alcance de esta zona',
            );
            console.log(
                'Hubo un problema al obtener el alcance de esta zona',
                error,
            );
        }

        setAlcanceLoading(false);
        setCantidadUsuariosSeleccionados(cantidadUsuarios);
    };

    // Mantener tabla sincronizada con el form y alcance
    useEffect(() => {
        if (listaZonasCoberturaData) {
            // Extraer datos del Array de Objetos
            // Convertirlo en datos para guardar en el "input" del "formulario"
            let zonasCobertura = [];
            listaZonasCoberturaData.forEach((item) => {
                let currentSet = listaZonasCoberturaData.filter(
                    (el) => el.franquiciaId === item.franquiciaId,
                );

                if (zonasCobertura.length > 0) {
                    let findZonaCobertura = zonasCobertura.findIndex(
                        (el) => el.franchise_id === currentSet[0].franquiciaId,
                    );
                    if (findZonaCobertura === -1) {
                        let tempObj = {};
                        tempObj['franchise_id'] = currentSet[0].franquiciaId;
                        if (currentSet[0].sucursalId) {
                            tempObj['branch_ids'] = [
                                ...new Set(
                                    currentSet.map((el) => el.sucursalId),
                                ),
                            ];
                            tempObj['zone_ids'] = [
                                ...new Set(currentSet.map((el) => el.zonaId)),
                            ];
                        }
                        zonasCobertura.push({ ...tempObj });
                    }
                } else {
                    let tempObj = {};
                    tempObj['franchise_id'] = currentSet[0].franquiciaId;
                    if (currentSet[0].sucursalId) {
                        tempObj['branch_ids'] = [
                            ...new Set(currentSet.map((el) => el.sucursalId)),
                        ];
                        tempObj['zone_ids'] = [
                            ...new Set(currentSet.map((el) => el.zonaId)),
                        ];
                    }
                    zonasCobertura.push({ ...tempObj });
                }
            });

            // Guardar datos de la "tabla" en el "formulario"s
            pushForm.setFieldsValue({ zonasCobertura: zonasCobertura });

            // Actualizar alcance
            if (listaZonasCoberturaData.length > 0) {
                obtenerCantidadUsuarios(zonasCobertura);
            } else {
                setCantidadUsuariosSeleccionados(0);
            }

            // Restablecer componente de Deeplink
            // Al cargar o eliminar zonas/franquicias se debe actualizar el componente
            // de deeplink para evitar mostrar secciones incorrectas
            setRestablecerDeeplink(true);
        }
    }, [listaZonasCoberturaData]);

    // Control para habilitar el boton de "Agregar Zona" a la lista
    useEffect(() => {
        if (
            franquiciasLoading ||
            sucursalesLoading ||
            zonasLoading ||
            franquiciasData.length <= 0 ||
            franquiciasDisabled
        ) {
            setAgregarButtonDisabled(true);
        } else {
            setAgregarButtonDisabled(false);
        }
    }, [franquiciasLoading, sucursalesLoading, zonasLoading]);

    // Cargar al hacer render del componente
    useEffect(() => {
        obtenerFranquicias();
    }, []);

    // Limpiar componente al hacer clic en el boton de "Restablecer"
    useEffect(() => {
        if (fields === null) {
            // Restablecer selects de "franquicia, sucursal, zona"
            setFranquiciasValue('');
            setFranquiciasData([]);
            setSucursalesValue('');
            setSucursalesData([]);
            setZonasValue('');
            setZonasData([]);
            // Restablecer datos del state de la tabla
            setListaZonasCoberturaData([]);
            // Restablecer fields del formulario
            pushForm.setFieldsValue({ zonasCobertura: null });
            // Volver a obtener datos de franquicias
            obtenerFranquicias();
        }
    }, [fields]);

    const columns = [
        {
            title: 'Franquicia',
            dataIndex: 'franquicia',
            key: 'franquicia',
        },
        {
            title: 'Sucursal',
            dataIndex: 'sucursal',
            key: 'sucursal',
        },
        {
            title: 'Zona',
            dataIndex: 'zona',
            key: 'zona',
        },
        {
            title: 'Acciones',
            dataIndex: 'acciones',
            key: 'acciones',
            width: 60,
            render: (_, record) => (
                <Button
                    danger
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => removerZonaHandler(record)}
                />
            ),
        },
    ];

    return (
        <>
            {/* form item de usuarios oculto */}
            <Form.Item
                name="zonasCobertura"
                style={{ display: 'none' }}
                rules={[{ required: true }]}>
                <Input name="zonasCobertura" type="hidden" />
            </Form.Item>

            {/* selects para agregar zona de cobertura */}
            <Row gutter="6">
                <Col span="7">
                    <p style={styles.label}>
                        {franquiciasRequired && (
                            <span style={styles.requiredTick}>* </span>
                        )}
                        Franquicia
                    </p>
                    <Select
                        name="franquicia"
                        showSearch
                        value={franquiciasValue}
                        style={styles.select}
                        loading={franquiciasLoading}
                        disabled={franquiciasDisabled}
                        onChange={(value) => handleChangeFranquicias(value)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }>
                        {franquiciasData?.map((item) => (
                            <Select.Option
                                key={Number(item.id)}
                                value={Number(item.id)}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>

                <Col span="8">
                    <p style={styles.label}>Sucursal</p>
                    <Select
                        name="sucursal"
                        showSearch
                        value={sucursalesValue}
                        style={styles.select}
                        loading={sucursalesLoading}
                        disabled={sucursalesDisabled}
                        onChange={(value) => handleChangeSucursales(value)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }>
                        {sucursalesData?.map((item) => (
                            <Select.Option
                                key={Number(item.id)}
                                value={Number(item.id)}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>

                <Col span="7">
                    <p style={styles.label}>
                        {zonasRequired && (
                            <span style={styles.requiredTick}>* </span>
                        )}
                        Zona
                    </p>
                    <Select
                        name="zona"
                        showSearch
                        style={styles.select}
                        value={zonasValue}
                        optionFilterProp="children"
                        loading={zonasLoading}
                        disabled={zonasDisabled}
                        onChange={(value) => handleChangeZonas(value)}
                        filterOption={(input, option) =>
                            option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }>
                        {zonasData?.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>

                <Col span="2" style={styles.botonAgregarContainer}>
                    <Button
                        shape="circle"
                        icon={<PlusOutlined />}
                        onClick={agregarZonaHandler}
                        disabled={agregarButtonDisabled}
                        loading={agregarButtonLoading}
                    />
                </Col>
            </Row>

            {/* lista de zonas agregadas */}
            {listaZonasCoberturaData.length > 0 ? (
                <Table
                    style={styles.zoneTable}
                    columns={columns}
                    dataSource={listaZonasCoberturaData}
                    showHeader={false}
                    pagination={false}
                    scroll={{ x: 300, y: 200 }}
                />
            ) : (
                <SinDatosPlaceholder
                    titulo="Primero agregue una zona"
                    style={styles.sinDatosPlaceholder}
                />
            )}
        </>
    );
};

export default SelectZonaCobertura;

const styles = {
    botonAgregarContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    select: { width: '100%' },
    label: {
        marginBottom: 5,
    },
    requiredTick: {
        color: 'red',
    },
    zoneTable: {
        marginTop: 10,
        height: 200,
    },
    sinDatosPlaceholder: {
        height: 200,
        paddingTop: 16,
    },
};

SelectZonaCobertura.propTypes = {
    pushForm: PropTypes.object,
    fields: PropTypes.any,
    setCantidadUsuariosSeleccionados: PropTypes.func,
    setRestablecerDeeplink: PropTypes.func,
    setAlcanceLoading: PropTypes.func,
};
