import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, Form, message, Space } from 'antd';

import styles from './NewPromBanner.module.css';
import ABMLayout from '../../../../../layouts/ABMLayout';
import GeneralInfo from '../../../../organisms/ProgramaRecompensas/Promociones/BannersPromo/GeneralInfo';
// import CombineCharacteristics from '../../../../organisms/ProgramaRecompensas/Promociones/BannersPromo/CombineCharacteristics';
// import SelectProducts from '../../../../organisms/ProgramaRecompensas/Promociones/BannersPromo/SelectProducts';
import SelectedAudience from '../../../../organisms/ProgramaRecompensas/Promociones/BannersPromo/SelectedAudience';
import ProgramPromotions from '../../../../organisms/ProgramaRecompensas/Promociones/BannersPromo/ProgramPromotions';
import getBase64 from '../../../../utils-components/getBase64';
import getErrorMessage from '../../../../utils-components/getErrorMessage';

const NewOrEdirPromBanner = () => {
    // Toda la lógica irá en un Custom Hook mas adelante
    const [form] = Form.useForm();
    const { id } = useParams();
    const history = useHistory();

    const [cantidadUsuariosSeleccionados, setCantidadUsuariosSeleccionados] =
        useState(); // Total de usuarios en la BD
    const [tipoZona, setTipoZona] = useState('1');
    const [restablecerDeeplink, setRestablecerDeeplink] = useState(false);
    const [alcanceLoading, setAlcanceLoading] = useState(false);

    const handleCancelButton = () =>
        history.push('/programa-recompensa/promociones/banners-promo/');

    const handleSaveButton = () => {
        form.validateFields()
            .then(async (fields) => {
                console.log(fields);
                try {
                    const promotionName = fields?.promotionName;
                    const imageFile = fields?.image?.file?.originFileObj;
                    const imageToBase64 = await getBase64(imageFile);
                } catch (error) {
                    const errorMessage = getErrorMessage(error);
                    message.error(errorMessage);
                }
            })
            .catch((error) => {
                const errorMessage = getErrorMessage(error);
                message.error(errorMessage);
            });
    };

    const actionButtons = (
        <div className={styles.actionsButtons}>
            <Button type="link" onClick={handleCancelButton}>
                Cancelar
            </Button>
            {/* <Button type="primary" onClick={handleSaveButton}>
                Guardar
            </Button> */}
        </div>
    );

    return (
        <ABMLayout titulo="Crear banner promocional" acciones={actionButtons}>
            <Card>
                <Form layout="vertical" form={form}>
                    <GeneralInfo />
                    {/* <Space className={styles.space}>
                        <CombineCharacteristics />
                        <SelectProducts />
                    </Space> */}
                    <ProgramPromotions />
                    <SelectedAudience
                        form={form}
                        tipoZona={tipoZona}
                        setCantidadUsuariosSeleccionados={
                            setCantidadUsuariosSeleccionados
                        }
                        setTipoZona={setTipoZona}
                        setRestablecerDeeplink={setRestablecerDeeplink}
                        setAlcanceLoading={setAlcanceLoading}
                    />
                </Form>
            </Card>
        </ABMLayout>
    );
};

export default NewOrEdirPromBanner;
