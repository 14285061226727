import React, { useContext } from 'react';
import { Modal } from 'antd';
import ModalContent from '../../../../atoms/ModalContent';
import PromotionsContext from '../../../../../context/programaRecompensa/Promociones/PromotionsContext';
import useListPromsBanners from '../../../../hooks/useListPromsBanners';

const DeactivatePromotionModal = () => {
    const {
        activatePromotion,
        enable,
        id,
        setShowDeactivatePromotionModal,
        showDeactivatePromotionModal,
    } = useContext(PromotionsContext);
    const { handleFinish } = useListPromsBanners();

    const handleOk = () => {
        activatePromotion(enable, id);
        handleCancel();
        handleFinish(undefined, true);
    };

    const handleCancel = () => {
        setShowDeactivatePromotionModal(false);
    };

    return (
        <Modal
            centered
            open={showDeactivatePromotionModal}
            footer={null}
            closable={false}>
            <ModalContent
                isDelete
                invertPrimary
                isLoading={false}
                showWarnIcon={true}
                title="¿Estás seguro de desactivar esta promoción?"
                okText="Desactivar"
                cancelText="Cancelar"
                onOk={handleOk}
                onCancel={handleCancel}
            />
        </Modal>
    );
};

export default DeactivatePromotionModal;
