import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProgramPromotionsCard.module.css';
import { Card, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const ProgramPromotionsCard = ({ day, hours }) => {
    let keyValue = 0;
    const getKey = () => {
        return keyValue++;
    };

    return (
        <Card title={day} className={styles.card}>
            <Space direction="vertical" className={styles.space}>
                {hours.map((hour) => (
                    <div className={styles.hourContainer} key={hour + getKey()}>
                        <p className={styles.hour}>{hour}</p>{' '}
                        <DeleteOutlined className={styles.icon} />
                    </div>
                ))}
            </Space>
        </Card>
    );
};

ProgramPromotionsCard.propTypes = {
    day: PropTypes.string,
    hours: PropTypes.array,
};

export default ProgramPromotionsCard;
