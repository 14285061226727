import React from 'react';
import { Card, Form, Select, Space, Typography } from 'antd';
import PushZonaCollapse from '../../../../molecules/Push/PushZonaCollapse';
import PropTypes from 'prop-types';
import styles from './SelectedAudience.module.css';
import useNewPromBanner from '../../../../hooks/useNewPromBanner';

const SelectedAudience = ({
    form,
    setCantidadUsuariosSeleccionados,
    tipoZona,
    setTipoZona,
    setRestablecerDeeplink,
    setAlcanceLoading,
}) => {
    const { Title } = Typography;
    const { Option } = Select;

    const { audience } = useNewPromBanner();

    return (
        <Card type="inner" title="Mostrar promo a público seleccionado">
            <Space direction="vertical" className={styles.space}>
                <Title level={5}>Mostrar por audiencia</Title>
                <Form.Item label="Seleccionar audiencia">
                    <Select className={styles.select}>
                        {audience?.map((audience) => (
                            <Option key={audience?.id} value={audience?.id}>
                                {audience?.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </Space>
            <Space direction="vertical" className={styles.space}>
                <Title level={5}>Mostrar por zona</Title>
                <PushZonaCollapse
                    pushForm={form}
                    setCantidadUsuariosSeleccionados={
                        setCantidadUsuariosSeleccionados
                    }
                    tipoZona={tipoZona}
                    setTipoZona={setTipoZona}
                    setRestablecerDeeplink={setRestablecerDeeplink}
                    setAlcanceLoading={setAlcanceLoading}
                />
            </Space>
        </Card>
    );
};

SelectedAudience.propTypes = {
    form: PropTypes.object,
    fields: PropTypes.array,
    setCantidadUsuariosSeleccionados: PropTypes.func,
    tipoZona: PropTypes.string,
    setTipoZona: PropTypes.func,
    setRestablecerDeeplink: PropTypes.bool,
    setAlcanceLoading: PropTypes.bool,
};

export default SelectedAudience;
