import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ListPromsBanners from './ListPromsBanners';
import NewOrEdirPromBanner from './NewOrEdirPromBanner';
import { Permisos } from '../../../../../constants/Permisos';
import ProtectedComponent from '../../../../../hoc/ProtectedComponent';
import PromotionsState from '../../../../../context/programaRecompensa/Promociones/PromotionsState';
import PropTypes from 'prop-types';

const BannersPromo = ({ match }) => {
    return (
        <PromotionsState>
            <Switch>
                <Route
                    exact
                    path={`${match.url}/banners-promo/`}
                    render={() => (
                        <ProtectedComponent
                            permiso={
                                Permisos.programaRecompensa.promociones
                                    .listarBanners
                            }
                            componente={ListPromsBanners}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${match.url}/banners-promo/nueva-promo`}
                    render={() => (
                        <ProtectedComponent
                            permiso={
                                Permisos.programaRecompensa.promociones
                                    .crearEditarBanner
                            }
                            componente={NewOrEdirPromBanner}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${match.url}/banners-promo/editar-promo/:id`}
                    render={() => (
                        <ProtectedComponent
                            permiso={
                                Permisos.programaRecompensa.promociones
                                    .crearEditarBanner
                            }
                            componente={NewOrEdirPromBanner}
                        />
                    )}
                />
            </Switch>
        </PromotionsState>
    );
};

export default BannersPromo;

BannersPromo.propTypes = {
    match: PropTypes.object,
};
